import fetch from '@/utils/fetch';

// For /comunicare/intrebari
export function getProductsQuestions(data) {
	return fetch({
		url: '/email/getProductEmails',
		method: 'post',
		data: data
	});
}

export function answerProductQuestion(data) {
	return fetch({
		url: '/email/answerProductEmail',
		method: 'post',
		data: data
	});
}

export function resolveQuestion(data) {
	return fetch({
		url: '/email/resolveQuestion',
		method: 'post',
		data: data
	});
}

// For /comunicare/service
export function getServiceRequests(data) {
	return fetch({
		url: '/email/getServiceRequests',
		method: 'post',
		data: data
	});
}

export function getServiceRequest(data) {
	return fetch({
		url: '/email/getServiceRequest',
		method: 'post',
		data: data
	});
}

export function answerServiceRequests(data) {
	return fetch({
		url: '/email/answerServiceRequest',
		method: 'post',
		data: data
	});
}

// For /comunicare/retur
export function getReturnRequests(data) {
	return fetch({
		url: '/email/getReturnRequests',
		method: 'post',
		data: data
	});
}

export function answerReturnRequests(data) {
	return fetch({
		url: '/email/answerReturnRequests',
		method: 'post',
		data: data
	});
}

export function getReturnRequest(data) {
	return fetch({
		url: '/email/getReturnRequest',
		method: 'post',
		data: data
	});
}

// For comunicare/feedback_comenzi
export function getOrdersFeedback(data) {
	return fetch({
		url: '/orders/getOrdersFeedback',
		method: 'post',
		data: data
	});
}

export function getReviews(data) {
	return fetch({
		url: '/email/getReviews',
		method: 'post',
		data: data
	});
}

export function verifyReview(data) {
	return fetch({
		url: '/email/verifyReview',
		method: 'post',
		data: data
	});
}

export function approveReview(data) {
	return fetch({
		url: '/email/approveReview',
		method: 'post',
		data: data
	});
}

export function respondReview(data) {
	return fetch({
		url: '/email/respondReview',
		method: 'post',
		data: data
	});
}

export function deleteReview(data) {
	return fetch({
		url: '/email/deleteReview',
		method: 'post',
		data: data
	});
}

export function getReviewAnswers(data) {
	return fetch({
		url: '/email/getReviewAnswers',
		method: 'post',
		data: data
	});
}

export function getOrdersContacts(data) {
	return fetch({
		url: '/email/getOrdersContacts',
		method: 'post',
		data: data
	});
}

export function getUnansweredOrderContact(data) {
	return fetch({
		url: '/email/getUnansweredOrderContact',
		method: 'post',
		data: data
	});
}

export function getClientFeedbacks(data) {
	return fetch({
		url: '/orders/getClientFeedbacks',
		method: 'post',
		data: data
	});
}

export function getComplaints(data) {
	return fetch({
		url: '/email/getComplaints',
		method: 'post',
		data: data
	});
}

export function sendComplaintResponse(data) {
	return fetch({
		url: '/email/sendComplaintResponse',
		method: 'post',
		data: data
	});
}

export function resolveComplaint(data) {
	return fetch({
		url: '/email/resolveComplaint',
		method: 'post',
		data: data
	});
}