import actions from './actions';
import gettersFile from './getters';
import mutationsFile from './mutations';
import { make } from 'vuex-pathify';

export const initialState = () => ({
    complaints: {
        data: [],
        start: 0,
        limit: 50,
        total: 0,
        all: 0,
        complaintId: '',
        clientName: '',
    },
    questions: {
        data: [],
        start: 0,
        limit: 50,
        total: 0,
        single: {
            id: null,
            products_model: null,
            products_name: null,
            nume: null,
            intrebare: null,
            data: null,
            url: null,
            products_url: null,
            resolved: null,
            chat: [],
        },
    },
    service: {
        filters: {
            start: 0,
            step: 50,
            minDate: '',
            maxDate: '',
            products_model: '',
            solved: 2,
        },
        data: [],
        total: 0,
        single: {
            id: null,
            nume: null,
            products_model: null,
            products_name: null,
            nrcomanda: null,
            nrfactura: null,
            products_url: null,
            resolved: null,
            data: null,
            chat: [],
        },
    },
    return: {
        filters: {
            start: 0,
            step: 50,
            minDate: '',
            maxDate: '',
            products_model: '',
            solved: 2,
        },
        data: [],
        total: 0,
        single: {
            id: null,
            nume: null,
            products_model: null,
            products_name: null,
            nrcomanda: null,
            nrfactura: null,
            products_url: null,
            nume_titular: null,
            iban: null,
            motiv: null,
            resolved: null,
            data: null,
            chat: [],
        },
    },
});

const mutations = {
    ...make.mutations(initialState()),
    ...mutationsFile,
};

const getters = {
    ...make.getters(initialState()),
    ...gettersFile,
};

export default function createCommunicationStore() {
    return {
        namespaced: true,
        state: initialState,
        actions,
        getters,
        mutations,
    };
}
