import {
    getComplaints,
    sendComplaintResponse,
    resolveComplaint,
    getProductsQuestions,
    answerProductQuestion,
    resolveQuestion,
    getServiceRequests,
    getServiceRequest,
    answerServiceRequests,
    getReturnRequests,
    getReturnRequest,
    answerReturnRequests,
} from '@/api/comunicare';
import store from '@/store/index';

export default {
    GetComplaints: ({ state }) => {
        const filters = {
            limit: state.complaints.limit,
            all: state.complaints.all,
            start: state.complaints.start,
        };
        if (state.complaints.complaintId) filters.complaintId = state.complaints.complaintId;
        if (state.complaints.clientName) filters.clientName = state.complaints.clientName;
        console.log('filters', filters);

        return getComplaints(filters).then((res) => store.set('communication/complaints', res.message));
    },
    SendComplaintResponse: ({ state }, data) => {
        return sendComplaintResponse({
            ...data,
            email: store.get('user/info@email'),
            name: store.get('user/info@name'),
        }).then((res) => window.location.reload());
    },
    ResolveComplaint: ({ state }, id) => {
        return resolveComplaint({
            id,
        }).then((res) => window.location.reload());
    },
    GetProductsQuestions: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getProductsQuestions({
                    limit: state.questions.limit,
                });

                store.set('communication/questions@data', res.message);

                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetProductQuestion: ({}, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getProductsQuestions(data);

                store.set('communication/questions@single', res.message.data);
                store.set('communication/questions@single.chat', res.message.chat);

                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    AnswerProductQuestion: ({}, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await answerProductQuestion(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    ResolveQuestion: ({}, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await resolveQuestion(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetServiceRequests: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getServiceRequests(state.service.filters);

                store.set('communication/service@data', res.message.requests);
                store.set('communication/service@total', res.message.count);

                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetServiceRequest: ({}, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getServiceRequest(data);

                store.set('communication/service@single', res.message.request);
                store.set('communication/service@single.chat', res.message.chat);

                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    AnswerServiceRequests: ({}, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await answerServiceRequests(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetReturnRequests: ({ state }) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getReturnRequests(state.return.filters);

                store.set('communication/return@data', res.message.requests);
                store.set('communication/return@total', res.message.count);

                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    GetReturnRequest: ({}, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await getReturnRequest(data);

                store.set('communication/return@single', res.message.request);
                store.set('communication/return@single.chat', res.message.chat);

                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
    AnswerReturnRequests: ({}, data) => {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await answerReturnRequests(data);
                if (res.message == null) return reject(new Error());
                return resolve(res);
            } catch (err) {
                return reject(err);
            }
        });
    },
};
